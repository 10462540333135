import _ from "lodash";
import { useDataProvider, usePermissions } from "react-admin";
import * as React from "react";
import { useEffect, useState } from "react";
import { DeliveryHistoryData } from "../../ts/interfaces/order-interface";
import { Spacer } from "../generics/spacer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {
  extractAuthClaims,
  hasPermissions,
  BooleanRoles
} from "../../ts/interfaces/role-interface";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( () =>
  createStyles( {
    fullWidth: {
      width: "100%",
    },
    table: {},
  } )
);

interface DeliveryHistoryProperties {
  id: string;
  targetResource: string;
}

const DeliveryNote = ( { note }: { note: DeliveryHistoryData } ) => (
  <>
    <Spacer />
    <Typography variant="h6">Resolved Note</Typography>
    <Spacer />
    <TableContainer>
      <Table aria-label="note-table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography align="left" noWrap={true}>
                Created On
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography align="left">Author</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography>Note</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow key={"note"}>
            <TableCell width={250} align="left">
              <Typography align="left" noWrap={true}>
                {note.updateTimestamp
                  ? new Date( note.updateTimestamp ).toLocaleString( "en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  } )
                  : ""}
                <br />
                {note.updateTimestamp
                  ? new Date( note.updateTimestamp ).toLocaleString( "en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  } )
                  : ""}
              </Typography>
            </TableCell>
            <TableCell width={250} align="left">
              <Typography align="left" noWrap={true}>
                {note.author}
              </Typography>
            </TableCell>
            <TableCell align="left">{note.note}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

export const DeliveryHistoryTable: React.FunctionComponent<
  DeliveryHistoryProperties
> = ( properties: DeliveryHistoryProperties ) =>
{
  const { loaded: permissionLoaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const isAdmin = hasPermissions( [BooleanRoles.Admin], roleClaims );

  const dataProvider = useDataProvider();
  const [deliveryHistory, setDeliveryHistory] = useState( [] );
  const [deliveryNote, setDeliveryNote] = useState( null );
  const classes = useStyles();

  useEffect( () =>
  {
    dataProvider
      .getDeliveryHistory( properties.targetResource, { id: properties.id } )
      .then(
        ( {
          data,
        }:
          | {
              data:
                | DeliveryHistoryData[]
                | {
                    deliveryHistory: DeliveryHistoryData[];
                    note: DeliveryHistoryData;
                  };
            }
          | {
              data: {
                deliveryHistory: DeliveryHistoryData[];
                note: DeliveryHistoryData;
              };
            } ) =>
        {
          console.log( data );
          // To tolerate the previous design of API
          if ( _.isArray( data ) )
          {
            setDeliveryHistory( data );
          }
          else if ( _.isArray( data.deliveryHistory ) )
          {
            setDeliveryHistory( data.deliveryHistory );
            if ( !_.isNil( data.note ) )
            {
              setDeliveryNote( data.note );
              console.log( data.note );
            }
          }
        }
      );
  }, [] );

  return (
    <React.Fragment>
      {permissionLoaded && isAdmin && deliveryNote && (
        <DeliveryNote note={deliveryNote} />
      )}
      <Spacer />
      <Typography variant={"h5"}>Delivery History</Typography>
      <Spacer />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Update date</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Status</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Carrier</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>
                  tracking number / code
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {_.isArray( deliveryHistory ) && deliveryHistory.length > 0 && (
            <TableBody>
              {deliveryHistory.map( ( entry ) => (
                <TableRow key={entry.id}>
                  <TableCell className={classes.table} align="left">
                    {entry.updateTimestamp &&
                    entry.updateTimestamp !== new Date( null ).toISOString()
                      ? new Date( entry.updateTimestamp ).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        }
                      )
                      : " "}
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    {entry.status}
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    {entry.carrier}
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    {entry.trackingCode}
                  </TableCell>
                </TableRow>
              ) )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Spacer />
    </React.Fragment>
  );
};
