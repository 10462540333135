import * as React from "react";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Tab,
  TabbedShowLayout,
  useDataProvider,
  useNotify,
  usePermissions
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Spacer } from "../generics/spacer";
import { BundledOrder } from "src/ts/interfaces/bundled-order-interface";
import { Address, Order } from "src/ts/interfaces/order-interface";
import { ShippingLabel } from "../potential-bundles/shipping-label";
import { BundledOrderTable } from "./bundled-order-table";
import { BundledPackingSlip } from "./bundled-packing-slip";
import { AuthContext } from "../../contexts/auth-context";
import {
  BooleanRoles,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router";

export interface BundledOrderRecord {
  bundle: BundledOrder;
  entries: Order[];
}
interface OrderMap {
  [x: string]: Order & { seller: { eventName?: string } };
}
interface DetailProperties {
  record?: BundledOrderRecord;
  permissions?: {
    exclusiveAddress?: string;
  };
  [x: string]: unknown;
}

const useStyles = makeStyles( () =>
  createStyles( {
    button: {
      marginLeft: "5px",
      fontSize: "14px",
      border: "1px solid black",
    },
    reShipOrderLabelSelect: {
      marginLeft: "15px",
      height: "43px",
      width: "100px",
    },
    inputField: {
      width: "70%",
      border: "1px solid",
      marginLeft: "40px",
      paddingLeft: "10px",
      "&.error": {
        borderColor: "red",
      },
    },
    asterisk: {
      color: "red",
    },
  } )
);

enum ReShipOrderLabelStatus {
  yes = "Yes",
  no = "No",
}

const generateOrderDetailLink = ( orderId: string ) => `#/orders/${orderId}/show`;

const OrdersDetails = ( {
  ordersData,
}: {
  ordersData: ( Order & { seller: { eventName?: string } } )[];
} ) =>
{
  const [expand, setExpand] = React.useState( false );
  const toggleAccordion = () =>
  {
    setExpand( ( expand ) => !expand );
  };
  return (
    <React.Fragment>
      <Accordion expanded={expand} onChange={toggleAccordion}>
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={<ExpandMoreIcon />}
          style={{
            backgroundColor: "#f5f5f5",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom>
                Order Details
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Event ID</TableCell>
                <TableCell>Event Name</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Product ID</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Seller</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersData.map( ( order ) => (
                <TableRow key={order.id}>
                  <TableCell>
                    <Link
                      href={generateOrderDetailLink( _.get( order, "id", " " ) )}
                    >
                      {_.get( order, "id", " " )}
                    </Link>
                  </TableCell>
                  <TableCell>{_.get( order, "seller.event", " " )}</TableCell>
                  <TableCell>{_.get( order, "seller.eventName", " " )}</TableCell>
                  <TableCell>
                    {( () =>
                    {
                      const firstname = _.get( order, "customer.firstname", "" );
                      const lastname = _.get( order, "customer.lastname", "" );
                      return `${firstname}${firstname ? " " : ""}${
                        lastname || " "
                      }`;
                    } )()}
                  </TableCell>
                  <TableCell>
                    {_.get( order, "cart.items[0].brandName", " " )}
                  </TableCell>
                  <TableCell>
                    {( () =>
                    {
                      const productName = _.get(
                        order,
                        "cart.items[0].productName",
                        ""
                      );
                      const description = _.get(
                        order,
                        "cart.items[0].description",
                        " "
                      );
                      return `${productName}${
                        productName && description ? "-" : ""
                      }${description ? description : ""}`;
                    } )()}
                  </TableCell>
                  <TableCell>
                    {_.get( order, "cart.items[0].externalProductId", " " )}
                  </TableCell>
                  <TableCell>{_.get( order, "totals.totalPay", " " )}</TableCell>
                  <TableCell>{_.get( order, "seller.handle", " " )}</TableCell>
                </TableRow>
              ) )}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export const BundledDetail: React.FunctionComponent<DetailProperties> = (
  detailProperties: DetailProperties
) =>
{
  const location = useLocation();
  const queryParams = new URLSearchParams( location.search );

  // Should be true if trying to generate a label for already created bundles
  const fromReGenerateBundle = queryParams.get( "reGenerate" ) === "true";
  const [showLabel, setShowLabel] = React.useState( false );

  const { record } = detailProperties;
  const initialAddress = record.bundle.address.line1 || "";
  const initialAddressLine2 = record.bundle.address.line2 || "";
  const initialCompany = record.bundle.address.company || "";
  const initialCity = record.bundle.address.city || "";
  const initialPostalCode = record.bundle.address.postalCode || "";
  const initialCountry = record.bundle.address.country || "";
  const initialFirstName = record.bundle.address.firstname || "";
  const initialLastName = record.bundle.address.lastname || "";
  const initialPhone = record.bundle.address.phone || "";
  const initialState = record.bundle.address.state || "";

  const [address, setAddress] = useState( initialAddress );
  const [addressLine2, setAddressLine2] = useState( initialAddressLine2 );
  const [company, setCompany] = React.useState( initialCompany );
  const [city, setCity] = React.useState( initialCity );
  const [postalCode, setPostalCode] = React.useState( initialPostalCode );
  const [country, setCountry] = React.useState( initialCountry );
  const [firstname, setFirstName] = React.useState( initialFirstName );
  const [lastname, setLastName] = React.useState( initialLastName );
  const [phone, setPhone] = React.useState( initialPhone );
  const [state, setState] = React.useState( initialState );

  const [isSaveEnabled, setSaveEnabled] = useState( false );
  const [disableInputs, setDisableInputs] = useState( false );

  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();

  const bundle = record.bundle;

  const orderMap = {} as OrderMap;
  record.entries.forEach( ( order ) =>
  {
    orderMap[order.id] = order;
  } );

  useEffect( () =>
  {
    const trimAddress = address.trim();
    const trimAddressLine2 = addressLine2.trim();
    const trimCity = city.trim();
    const trimPostalCode = postalCode.trim();
    const trimCountry = country.trim();
    const trimFirstname = firstname.trim();
    const trimLastname = lastname.trim();
    const trimPhone = phone.trim();
    const trimState = state.trim();

    const hasChanges =
      trimAddress !== initialAddress.trim() ||
      trimAddressLine2 !== initialAddressLine2.trim() ||
      trimCity !== initialCity.trim() ||
      trimPostalCode !== initialPostalCode.trim() ||
      trimCountry !== initialCountry.trim() ||
      company.trim() !== initialCompany.trim() ||
      trimFirstname !== initialFirstName.trim() ||
      trimLastname !== initialLastName.trim() ||
      trimPhone !== initialPhone.trim() ||
      trimState !== initialState.trim();

    const allRequiredFieldsFilled =
      trimAddress !== "" &&
      trimCity !== "" &&
      trimPostalCode !== "" &&
      trimCountry !== "" &&
      trimPhone !== "" &&
      trimFirstname !== "" &&
      trimLastname !== "" &&
      trimState !== "";

    setSaveEnabled( hasChanges && allRequiredFieldsFilled );
  }, [
    address,
    addressLine2,
    company,
    city,
    postalCode,
    country,
    firstname,
    lastname,
    phone,
    state,
  ] );

  const closePackingSlip = () =>
  {
    setShowLabel( false );
  };

  const toggleRatesGenerated = ( disableInputs: boolean ) =>
  {
    const fields = [
      { value: address, setValue: setAddress, initial: initialAddress },
      { value: city, setValue: setCity, initial: initialCity },
      {
        value: postalCode,
        setValue: setPostalCode,
        initial: initialPostalCode,
      },
      { value: country, setValue: setCountry, initial: initialCountry },
      { value: firstname, setValue: setFirstName, initial: initialFirstName },
      { value: lastname, setValue: setLastName, initial: initialLastName },
      { value: phone, setValue: setPhone, initial: initialPhone },
      { value: state, setValue: setState, initial: initialState },
    ];

    fields.forEach( ( { value, setValue, initial } ) =>
    {
      if ( !value.trim() )
      {
        setValue( initial );
      }
    } );

    setDisableInputs( disableInputs );
  };

  const showPackingSlip = () =>
  {
    setShowLabel( true );
  };

  const [allowRegenerate, setAllowRegenerate] = React.useState<string>(
    detailProperties.record?.bundle?.allowRegenerateShippingLabel || false
      ? ReShipOrderLabelStatus.yes
      : ReShipOrderLabelStatus.no
  );

  const handleChange = ( data: {
    allowRegenerateShippingLabel?: boolean;
    address?: Address;
  } ) =>
  {
    dataProvider
      .updateBundledOrderById( "bundled-orders", {
        bundleId: record.bundle.id,
        ...data,
      } )
      .then( () =>
      {
        if ( typeof data.allowRegenerateShippingLabel === "boolean" )
        {
          setAllowRegenerate(
            allowRegenerate === ReShipOrderLabelStatus.yes
              ? ReShipOrderLabelStatus.no
              : ReShipOrderLabelStatus.yes
          );
          notify(
            `${
              !( allowRegenerate === ReShipOrderLabelStatus.yes )
                ? "Allowed"
                : "Not Allowed"
            } Re-Ship Order `
          );
          return;
        }

        setSaveEnabled( false );
        window.location.reload();
      } )
      .catch( ( error: Error ) =>
      {
        notify( `${error.message}`, "warning" );
      } );
  };

  const updateAddress = () =>
  {
    const updateData = {
      ...record.bundle.address,
      line1: address,
      line2: addressLine2 || "",
      state,
      city,
      postalCode,
      country,
      firstname,
      lastname,
      phone,
      company: company || "",
    } as Address;
    handleChange( { address: updateData } );
  };

  const updateReShipOrder = () =>
  {
    handleChange( {
      allowRegenerateShippingLabel: !(
        allowRegenerate === ReShipOrderLabelStatus.yes
      ),
    } );
  };

  return fromReGenerateBundle ? (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5" gutterBottom>
              {`Bundled Order ${detailProperties.record.bundle.id}`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={4}
            >
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h6">
                    Address Line1<span className={classes.asterisk}> *</span>:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={address}
                    disabled={disableInputs}
                    onChange={( e ) => setAddress( e.target.value )}
                    placeholder="Enter Address Line1"
                    className={`${classes.inputField} ${
                      !address ? "error" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">{` Address Line2:`}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={addressLine2}
                    disabled={disableInputs}
                    onChange={( e ) => setAddressLine2( e.target.value )}
                    placeholder="Enter Address Line2"
                    className={classes.inputField}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">
                    City<span className={classes.asterisk}> *</span>:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={city}
                    disabled={disableInputs}
                    onChange={( e ) => setCity( e.target.value )}
                    placeholder="Enter City"
                    className={`${classes.inputField} ${!city ? "error" : ""}`}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">
                    State<span className={classes.asterisk}> *</span>:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={state}
                    disabled={disableInputs}
                    onChange={( e ) => setState( e.target.value )}
                    placeholder="Enter State"
                    className={`${classes.inputField} ${!state ? "error" : ""}`}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">
                    Postal/Zip<span className={classes.asterisk}> *</span>:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={postalCode}
                    disabled={disableInputs}
                    onChange={( e ) => setPostalCode( e.target.value )}
                    placeholder="Enter Postal/Zip"
                    className={`${classes.inputField} ${
                      !postalCode ? "error" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">
                    Country/Region<span className={classes.asterisk}> *</span>:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={country}
                    disabled={disableInputs}
                    onChange={( e ) => setCountry( e.target.value )}
                    placeholder="Enter Country/Region"
                    className={`${classes.inputField} ${
                      !country ? "error" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">{`Company:`}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={company}
                    disabled={disableInputs}
                    onChange={( e ) => setCompany( e.target.value )}
                    placeholder="Enter Company"
                    className={classes.inputField}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">
                    First Name<span className={classes.asterisk}> *</span>:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={firstname}
                    disabled={disableInputs}
                    onChange={( e ) => setFirstName( e.target.value )}
                    placeholder="Enter First Name"
                    className={`${classes.inputField} ${
                      !firstname ? "error" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">
                    Last Name<span className={classes.asterisk}> *</span>:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={lastname}
                    disabled={disableInputs}
                    onChange={( e ) => setLastName( e.target.value )}
                    placeholder="Enter Last Name"
                    className={`${classes.inputField} ${
                      !lastname ? "error" : ""
                    }`}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="h6">
                    Phone<span className={classes.asterisk}> *</span>:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Input
                    value={phone}
                    disabled={disableInputs}
                    onChange={( e ) => setPhone( e.target.value )}
                    placeholder="Enter Phone"
                    className={`${classes.inputField} ${!phone ? "error" : ""}`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updateAddress}
                    style={{ marginTop: "30px", width: "100px" }}
                    disabled={!isSaveEnabled}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box paddingTop={2} marginLeft={1} textAlign="left">
            <Button onClick={showPackingSlip}>Print Packaging Slip</Button>
          </Box>
        </Grid>
        <Spacer />
        <OrdersDetails ordersData={detailProperties.record.entries} />
        <Spacer />
        <ShippingLabel
          bundledOrder={bundle}
          orderMap={orderMap}
          toggleRatesGenerated={toggleRatesGenerated}
          isSaveEnabled={isSaveEnabled}
        />
        <AuthContext.Provider
          value={{ permissions: detailProperties.permissions }}
        >
          <BundledPackingSlip
            key={bundle.id}
            bundledOrder={bundle}
            orders={record.entries}
            showLabel={showLabel}
            onUnload={closePackingSlip}
          />
        </AuthContext.Provider>
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography variant="h5" gutterBottom>
              {`Bundled Order ${record.bundle.id}`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={4}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant={"h6"}
                  >{`Address: ${record.bundle.address.line1}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Postal/Zip: ${record.bundle.address.postalCode}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`City: ${record.bundle.address.city}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Country/Region: ${record.bundle.address.country}`}</Typography>
                  <Typography variant={"h6"}>{`Company: ${
                    record.bundle.address.company || ""
                  }`}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="h5" gutterBottom>
              {`Shipping Details`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
              marginLeft={1}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant={"h6"}>{`First Name: ${
                    firstname || ""
                  }`}</Typography>
                  <Typography variant={"h6"}>{`Last Name: ${
                    lastname || ""
                  }`}</Typography>
                  <Typography variant={"h6"}>{`Phone: ${
                    phone || ""
                  }`}</Typography>
                </Grid>
              </Grid>
            </Box>
            {hasPermissions(
              [BooleanRoles.Admin, BooleanRoles.CustomerService],
              permissions
            ) &&
              record?.bundle?.status === "shipped" && (
              <Box
                border={2}
                bgcolor={"#f7f9fa"}
                css={{ borderColor: "#C5D1D9" }}
                padding={2}
                marginLeft={1}
                marginTop={2}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant={"h6"}>
                      {`Re-Ship Order `}
                      <Select
                        label="Re-Ship Order"
                        name="reShipOrder"
                        value={allowRegenerate}
                        onChange={updateReShipOrder}
                        placeholder={"Re-Ship Order"}
                        className={classes.reShipOrderLabelSelect}
                      >
                        <MenuItem value={ReShipOrderLabelStatus.yes}>
                          {ReShipOrderLabelStatus.yes}
                        </MenuItem>
                        <MenuItem value={ReShipOrderLabelStatus.no}>
                          {ReShipOrderLabelStatus.no}
                        </MenuItem>
                      </Select>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
        <Spacer />
        <TabbedShowLayout>
          <Tab label="Orders">
            <Box>
              <Spacer />
              <Button onClick={showPackingSlip}>Print Packaging Slip</Button>
              <Spacer />
            </Box>
            <BundledOrderTable orders={record.entries} bundleId={bundle.id} />
            <Spacer />
          </Tab>
        </TabbedShowLayout>
        <AuthContext.Provider
          value={{ permissions: detailProperties.permissions }}
        >
          <BundledPackingSlip
            key={bundle.id}
            bundledOrder={bundle}
            orders={record.entries}
            showLabel={showLabel}
            onUnload={closePackingSlip}
          />
        </AuthContext.Provider>
      </CardContent>
    </Card>
  );
};
