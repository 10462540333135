import * as React from "react";
import { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDataProvider } from "react-admin";
import { Box, Button, IconButton, TextField } from "@material-ui/core";
import { ButtonStyles } from "../generics/button-styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { SellerPermittedEventTypes } from "../../ts/interfaces/user-interface";

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
      width: "400px",
    },
    button: {
      marginLeft: "5px",
      marginTop: "30px",
      width: "110px",
      border: "1px solid black",
    },
    trendingSellerButton: {
      marginLeft: "5px",
    },
    errorMessage: {
      color: "red",
    },
    successMessage: {
      color: "green",
    },
    dialog: {
      minHeight: "200px",
    },
    messageBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      height: "40px",
      paddingLeft: "15px",
      paddingRight: "20px",
      marginTop: "10px",
      fontSize: "15px",
      border: "0.2px solid black",
      borderRadius: "5px",
    },
    messageText: {
      flexGrow: 1,
    },
    closeButton: {
      marginLeft: "10px",
      width: "10px",
      height: "10px",
    },
  } )
);

export function AddTrendingSeller( {
  selectedTab,
}: {
  selectedTab: SellerPermittedEventTypes;
} )
{
  const [dialogState, setDialogState] = useState( false );
  const classes = useStyles();
  const buttonClasses = ButtonStyles();
  const dataProvider = useDataProvider();

  const [formValues, setFormValues] = useState( {
    customerId: "",
    rank: 50,
  } );
  const [saveDisabled, setSaveDisabled] = useState( true );
  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>( null );

  const validateData = ( values: typeof formValues ) =>
  {
    const customerIdIsValid = values.customerId.trim() !== "";
    return customerIdIsValid;
  };

  const handleInputChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    const { name, value } = event.target;
    setFormValues( ( prev ) =>
    {
      const updatedValues = {
        ...prev,
        [name]: name === "rank" && value === "" ? 50 : value,
      };
      setSaveDisabled( !validateData( updatedValues ) );
      return updatedValues;
    } );
  };

  const handleSave = () =>
  {
    dataProvider
      .updateUserById( "users", {
        rank: 50,
        customerId: formValues.customerId,
        permittedEventTypes: [selectedTab],
      } )
      .then( () =>
      {
        setMessage( {
          text: `The seller has successfully been added to the ${
            selectedTab === SellerPermittedEventTypes.closetSale
              ? "Marketplace"
              : "Events"
          } trending list`,
          type: "success",
        } );
        setFormValues( { customerId: "", rank: 50 } );
        setSaveDisabled( true );
      } )
      .catch( ( error: Error ) =>
      {
        const message = `Customer ${formValues.customerId} not found`;

        setMessage( {
          text:
            error.message === message
              ? "The customer Id entered is invalid."
              : error.message,
          type: "error",
        } );
        setFormValues( { customerId: "", rank: 50 } );
        setSaveDisabled( true );
      } );
  };

  return (
    <>
      <Dialog
        open={dialogState}
        onClose={() =>
        {
          setDialogState( false );
        }}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          Add Seller to Trending List
          <IconButton
            edge="end"
            color="inherit"
            onClick={() =>
            {
              setDialogState( false );
              setFormValues( { customerId: "", rank: 50 } );
              setSaveDisabled( true );
              setMessage( null );
            }}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TextField
              label="Customer ID"
              name="customerId"
              value={formValues.customerId}
              onChange={handleInputChange}
              className={classes.formField}
              variant="outlined"
            />
            {message && (
              <Box
                className={`${classes.messageBox} ${
                  message.type === "success"
                    ? classes.successMessage
                    : classes.errorMessage
                }`}
              >
                <span className={classes.messageText}>{message.text}</span>
                <IconButton
                  edge="end"
                  onClick={() => setMessage( null )}
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <Button
                className={[buttonClasses.saveButton, classes.button].join( " " )}
                disabled={saveDisabled}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Button
        color="primary"
        className={[classes.trendingSellerButton].join( " " )}
        onClick={() =>
        {
          setDialogState( true );
        }}
      >
        Add Trending Seller
      </Button>
    </>
  );
}
