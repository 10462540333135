import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Create, SimpleForm, TextInput } from "ra-ui-materialui";
import { ResourceComponentProps } from "ra-core";
import {
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  useDataProvider,
  useDelete,
  useRefresh
} from "react-admin";
import { useNotification } from "../custom-notification/notification";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { SellerPermittedEventTypes } from "../../ts/interfaces/user-interface";
import { AddTrendingSeller } from "./create";

const useStyles = makeStyles( {
  card: {
    margin: "10px",
  },
  "border-top": {
    borderTop: "thin solid lightgrey",
  },
  "primary-text": {
    fontWeight: "bold",
  },
  "secondary-text": {
    fontWeight: "normal",
    fontSize: "x-small",
    "& span": {
      display: "inline",
      "& svg": {
        fontSize: "x-small",
      },
    },
  },
  "padding-1rem": {
    padding: "1rem",
  },
  "max-width": {
    maxWidth: "80%",
  },
  "left-padding": {
    paddingLeft: "8px",
  },
  "no-left-padding": {
    padding: "8px 0px",
  },
} );

interface TrendingSellersData {
  sellerId: string;
  sellerHandle: string;
  sellerType: string;
  permittedEventTypes: SellerPermittedEventTypes[];
  rank: number;
}

enum EventType {
  marketPlace = "closetSale",
  events = "regular",
}

const SellerDetails = ( seller: TrendingSellersData ) =>
{
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          className={classes["primary-text"]}
          id={seller.sellerId + "-handle"}
        >
          {seller.sellerHandle}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          className={classes["secondary-text"]}
          id={seller.sellerId + "-id"}
        >
          Seller Id: {seller.sellerId}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          className={classes["secondary-text"]}
          id={seller.sellerId + "-SellerType"}
        >
          Seller type: {seller.sellerType}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          className={classes["secondary-text"]}
          id={`${seller.sellerId}-Posts`}
        >
          Posts:{" "}
          {seller.permittedEventTypes
            .map( ( el ) =>
              el === SellerPermittedEventTypes.closetSale
                ? "Marketplace"
                : "Events"
            )
            .join( ", " )}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SellerDetailCard = ( properties: {
  seller: TrendingSellersData;
  includeInput: boolean;
  eventType: string;
} ) =>
{
  const classes = useStyles();
  const { seller, includeInput, eventType } = properties;

  const refresh = useRefresh();
  const { showNotification } = useNotification();
  const [deleteOne] = useDelete(
    "trending-sellers",
    seller.sellerId,
    {
      eventType: [eventType],
    },
    {
      onSuccess: async () =>
      {
        showNotification( {
          message: "Seller Removed",
          type: "notify",
          timeout: 2000,
          onClose: () => window.location.reload(),
        } );
      },
      onFailure: async ( error: Error ) =>
      {
        showNotification( {
          message: error.message,
          type: "error",
          onClose: () => refresh(),
        } );
      },
    }
  );

  return (
    <Card>
      <Grid container className={classes["padding-1rem"]}>
        {includeInput && (
          <Grid item xs={4} sm={4} md={1}>
            <TextInput
              validate={( value ) =>
              {
                if ( isNaN( value ) || value === "" )
                {
                  return "Please enter a valid number";
                }
                return undefined;
              }}
              source={seller.sellerId + "-rank"}
              label="Rank"
              defaultValue={seller.rank}
              className={classes["max-width"]}
            />
          </Grid>
        )}
        <Grid item xs={8} sm={10} className={classes["no-left-padding"]}>
          <SellerDetails {...seller} />
        </Grid>

        <Grid item xs={12} sm={1} container justifyContent="flex-end">
          <Tooltip title="Remove">
            <IconButton
              aria-label="delete"
              onClick={() => deleteOne()}
              color="secondary"
            >
              <RemoveCircle style={{ color: "#f44336" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  );
};

const updateSellersRank =
  ( eventType: EventType, currentSellers: TrendingSellersData[] ) =>
    ( data: { [id: string]: number } ) =>
    {
      const dataToUpdate = Object.keys( data )
        .map( ( key ) =>
        {
          const splitKey = key.split( "-" );
          const id = splitKey[0];
          const newRank = Number( data[key] );
          const currentSeller = currentSellers.find(
            ( seller ) => seller.sellerId === id
          );

          if ( currentSeller.rank !== newRank )
          {
            return {
              id,
              [eventType === EventType.marketPlace
                ? "marketplaceRank"
                : "eventRank"]: newRank,
            };
          }

          return null;
        } )
        .filter( Boolean );

      return {
        id: "",
        creation: {
          data: dataToUpdate,
          eventType,
        },
      };
    };

const SellersRankUpdate = (
  properties: ResourceComponentProps & {
    eventType: EventType;
    selectedTab: SellerPermittedEventTypes;
  }
): JSX.Element =>
{
  const { eventType, selectedTab, ...otherProperties } = properties;
  const dataProvider = useDataProvider();
  const [sellers, setSellers] = useState( [] );
  const refresh = useRefresh();
  useEffect( () =>
  {
    dataProvider
      .listTrendingSellers( "trending-sellers", {
        eventType,
      } )
      .then( ( { data }: { data: { hassan: string }[] } ) =>
      {
        setSellers( data );
      } );
  }, [dataProvider, eventType] );

  const { showNotification } = useNotification();
  const success = ( response: { data: { noUpdate?: boolean } } ) =>
  {
    if ( _.get( response, "data.noUpdate", false ) === true )
    {
      showNotification( {
        message: "Nothing to update",
        type: "notify",
        timeout: 2000,
      } );
    }
    else
    {
      showNotification( {
        message: "Rank updated",
        type: "notify",
        timeout: 2000,
        onClose: () => window.location.reload(),
      } );
    }
  };

  const failure = ( error: Error ) =>
  {
    showNotification( {
      message: error.message,
      type: "error",
      onClose: () => refresh(),
    } );
  };

  return (
    <Create
      {...otherProperties}
      key={sellers.length}
      transform={updateSellersRank( eventType, sellers )}
      onSuccess={success}
      onFailure={failure}
      title="Manage Trending Sellers"
    >
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8}>
                <Typography variant="h5">{`Update ${
                  eventType === EventType.marketPlace ? "Marketplace" : "Event"
                } Trending Sellers`}</Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <AddTrendingSeller selectedTab={selectedTab} />
              </Grid>
            </Grid>
          </Grid>
          {sellers?.length > 0 &&
            sellers.map( ( seller: TrendingSellersData ) => (
              <Grid item xs={12} key={seller.sellerId}>
                <SellerDetailCard
                  {...{ seller, includeInput: true, eventType }}
                />
              </Grid>
            ) )}
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const TrendingSellers = (
  properties: ResourceComponentProps
): JSX.Element =>
{
  const [selectedTab, setSelectedTab] = useState<SellerPermittedEventTypes>(
    SellerPermittedEventTypes.regular
  );

  return (
    <TabbedShowLayout
      tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
    >
      <Tab
        label="Events"
        onClick={() => setSelectedTab( SellerPermittedEventTypes.regular )}
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <SellersRankUpdate
              {...{ ...properties, eventType: EventType.events, selectedTab }}
            />
          </Grid>
        </Grid>
      </Tab>
      <Tab
        label="Marketplace"
        onClick={() => setSelectedTab( SellerPermittedEventTypes.closetSale )}
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <SellersRankUpdate
              {...{
                ...properties,
                eventType: EventType.marketPlace,
                selectedTab,
              }}
            />
          </Grid>
        </Grid>
      </Tab>
    </TabbedShowLayout>
  );
};
