import * as React from "react";
import {
  BooleanField,
  Button,
  Datagrid,
  Filter,
  FunctionField,
  List,
  SelectInput,
  setListSelectedIds,
  TextField,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
  useUpdate
} from "react-admin";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { FilterProps } from "ra-ui-materialui";
import { useDispatch } from "react-redux";

import { BundledOrder } from "../../ts/interfaces/bundled-order-interface";
import { Manifest } from "../../ts/interfaces/manifest-interface";
import {
  makeLocalStorageKey,
  StoragePage,
  StorageType
} from "../../utils/local-storage";
import { BasicPagination, perPage } from "../generics/pagination";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { usePermissions } from "ra-core";
import { notifyMessage } from "../../utils/primitive";
import _ from "lodash";
import { Box } from "@material-ui/core";
import {
  UserContextProvider,
  useUserContext
} from "../../contexts/user-context";

const BundleFilters: React.FC = ( properties: FilterProps ) => (
  <Filter {...properties}>
    <SelectInput
      source="status"
      choices={[
        { id: "created", name: "created" },
        { id: "shipped", name: "shipped" },
      ]}
    />
    <SelectInput
      source="hasManifest"
      choices={[
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ]}
    />
  </Filter>
);

const manifestStorageKey = makeLocalStorageKey(
  StoragePage.manifestList,
  StorageType.manifest,
  "list"
);
const BulkActions = ( props: { selectedIds: string[] } ) =>
{
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const { data } = useListContext();
  const dataProvider = useDataProvider();

  const { selectedIds } = props;
  const [init, setInit] = React.useState( true );
  const [createdManifestIds, setManifestIds] = React.useState( [[], []] );
  let bundleIds = [...selectedIds];
  if ( init )
  {
    setInit( false );
    const restoredManifestStorage =
      JSON.parse( localStorage.getItem( manifestStorageKey ) ) || {};
    const { storedList = [] } = restoredManifestStorage;
    if ( storedList?.length > 0 )
    {
      bundleIds = [...storedList];
      dispatch( setListSelectedIds( "bundled-orders", storedList ) );
    }
  }
  const shipmentIds: string[] = [];

  const manifestStorage: {
    storedList: string[];
    shippingMap: { [bundledId: string]: string };
  } = {
    storedList: bundleIds,
    shippingMap: {},
  };
  bundleIds.forEach( ( id ) =>
  {
    const localStorageShippingId = manifestStorage.shippingMap[id] || undefined;
    const listContextShippingId = data[id]?.shipping.labelProvider.shipmentId;
    const prevailingShippingId =
      listContextShippingId || localStorageShippingId;
    manifestStorage.shippingMap[id] = prevailingShippingId;
    shipmentIds.push( prevailingShippingId );
  } );
  localStorage.setItem( manifestStorageKey, JSON.stringify( manifestStorage ) );

  const [open, setOpen] = React.useState( false );
  const [manifestUrl, setManifestUrl] = React.useState( "" );

  const [update] = useUpdate(
    "manifests",
    "manifest-id",
    {
      bundleIds,
      shipmentIds,
    },
    {},
    {
      onSuccess: ( response: { data: { id: string; manifest: Manifest } } ) =>
      {
        if ( response.data.manifest.form_url )
        {
          setManifestIds( [bundleIds, shipmentIds] );
          setManifestUrl( response.data.manifest.form_url );
          localStorage.removeItem( manifestStorageKey );
          dispatch( setListSelectedIds( "bundled-orders", [] ) );
          window.open( response.data.manifest.form_url, "_blank" ).focus();
          setOpen( true );
        }
        else
        {
          notify( "Error creating manifest", "error" );
          refresh();
        }
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  const [partnerUpdate] = useUpdate(
    "partner-manifests",
    "manifest-id",
    {
      bundleIds,
      shipmentIds,
    },
    {},
    {
      onSuccess: ( response: { data: { id: string; manifest: Manifest } } ) =>
      {
        if ( response.data.manifest.form_url )
        {
          setManifestIds( [bundleIds, shipmentIds] );
          setManifestUrl( response.data.manifest.form_url );
          localStorage.removeItem( manifestStorageKey );
          dispatch( setListSelectedIds( "bundled-orders", [] ) );
          window.open( response.data.manifest.form_url, "_blank" ).focus();
          setOpen( true );
        }
        else
        {
          notify( "Error creating manifest", "error" );
          refresh();
        }
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  const handleClose = () =>
  {
    setManifestUrl( "" );
    setOpen( false );
    refresh();
  };

  const handleClick = async () =>
  {
    const bundledOrderIds = manifestStorage?.storedList;
    if ( !Array.isArray( bundledOrderIds ) || !bundledOrderIds )
    {
      notify( "At least one must be selected to generate a label.", "error" );
      return;
    }

    const allowedBundledOrders: BundledOrder[] = [];
    const notAllowedBundledOrders: string[] = [];
    for ( const bundledOrderId of bundledOrderIds )
    {
      const allowGenerateLabel =
        data[bundledOrderId]?.allowRegenerateShippingLabel &&
        !data[bundledOrderId]?.reshippedInBundle;
      if ( allowGenerateLabel )
      {
        allowedBundledOrders.push( data[bundledOrderId] );
      }
      else
      {
        notAllowedBundledOrders.push( bundledOrderId );
      }
    }

    if ( notAllowedBundledOrders.length > 0 )
    {
      notify(
        `The following Bundled Orders are not eligible for Re-Ship Order : ${notAllowedBundledOrders.join(
          ", "
        )}`,
        "error"
      );
      return;
    }

    const [firstBundledOrder] = allowedBundledOrders;
    const referenceAddress = firstBundledOrder.address;
    const mismatchedIds = allowedBundledOrders
      .filter( ( item ) => !_.isEqual( item.address, referenceAddress ) )
      .map( ( item ) => item.id );

    if ( mismatchedIds.length > 0 )
    {
      notify(
        `The following bundled orders have mismatched addresses and are not eligible with the first bundled order (ID: ${
          firstBundledOrder.id
        }): ${mismatchedIds.join( ", " )}`,
        "error"
      );
      return mismatchedIds;
    }

    const allOrderIds = allowedBundledOrders.flatMap(
      ( allowedBundledOrder ) => allowedBundledOrder.orderIds
    );

    const orderIds = [...new Set( allOrderIds )];

    const creation = {
      fulfillmentService: "ShopThing",
      orderIds,
      address: firstBundledOrder.address,
      reGenerateLabel: true,
      regeneratedFromBundledOrders: allowedBundledOrders.map(
        ( bundledOrder ) => bundledOrder.id
      ),
      customerNotes: "Thank you for shopping with ShopThing",
    };
    try
    {
      const { data } = await dataProvider.create( "bundled-orders", {
        data: { creation },
      } );
      setTimeout( () =>
      {
        // Clear selected ids and then navigate
        dispatch( setListSelectedIds( "bundled-orders", [] ) );
        window.location.href = `/#/bundled-orders/${data.id}/show?reGenerate=true`;
      }, 200 );
    }
    catch ( error )
    {
      notify( error.message, "error" );
    }
  };

  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const isPartner = hasPermissions( [BooleanRoles.Partner], roleClaims );
  const isAdmin = hasPermissions( [BooleanRoles.Admin], roleClaims );
  const isSeller = hasPermissions( [BooleanRoles.Seller], roleClaims );
  const isCs = hasPermissions( [BooleanRoles.CustomerService], roleClaims );

  return React.useMemo( () =>
  {
    const allowGenerateLabel = bundleIds.some(
      ( value ) =>
        data[value]?.allowRegenerateShippingLabel &&
        !data[value]?.reshippedInBundle
    );
    const allowCreateManifest = bundleIds.some(
      ( value ) => !data[value]?.hasManifest && !data[value]?.reshippedInBundle
    );

    return (
      <React.Fragment>
        {( isPartner || isAdmin || isSeller || isCs ) && allowGenerateLabel && (
          <Button onClick={handleClick} label="Generate Label" />
        )}
        {( isAdmin || isPartner ) && allowCreateManifest && (
          <Button
            onClick={loaded && isPartner ? partnerUpdate : update}
            label="Create Manifest"
          />
        )}
        <CustomModal open={open} onClose={handleClose}>
          <h2>Successfully created shipping manifest!</h2>
          <h4>
            You can view the manifest{" "}
            <a href={manifestUrl} target="_blank" rel="noopener noreferrer">
              here
            </a>
          </h4>
          <div>
            The manifest was created for bundles [
            {createdManifestIds[0].join( ", " )}]
          </div>
          <div>with shipment ids [{createdManifestIds[1].join( ", " )}]</div>
        </CustomModal>
      </React.Fragment>
    );
  }, [bundleIds] );
};

const RenderUrl = ( props: { url: string; label: string } ) =>
{
  const { url, label } = props;
  if ( url )
  {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={( e ) => e.stopPropagation()}
      >
        {label}
      </a>
    );
  }

  return null;
};

const BundledOrderListInner = (
  properties: Record<string, never>
): JSX.Element =>
{
  const { hasAccessToCommercialInvoice } = useUserContext();
  const { loaded, permissions } = usePermissions();
  const hasOtherRoles = hasPermissions(
    [
      BooleanRoles.Fulfillment,
      BooleanRoles.CustomerService,
      BooleanRoles.FulfillmentReadOnly,
      BooleanRoles.RefundCustomerService,
    ],
    permissions
  );
  const roleClaims = extractAuthClaims( permissions );
  const isPartner = hasPermissions( [BooleanRoles.Partner], roleClaims );
  const isAdmin = hasPermissions( [BooleanRoles.Admin], roleClaims );
  const isCs = hasPermissions( [BooleanRoles.CustomerService], roleClaims );

  return (
    <List
      {...properties}
      title="Bundled Orders"
      pagination={<BasicPagination />}
      perPage={perPage}
      filters={<BundleFilters />}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Props are passed via context
      bulkActionButtons={<BulkActions />}
    >
      <Datagrid
        rowClick="show"
        isRowSelectable={( record: BundledOrder ) =>
          // Show checkbox to generate label
          ( record.allowRegenerateShippingLabel && !record.reshippedInBundle ) ||
          // Show checkbox to generate manifest
          ( !record.hasManifest &&
            !record.reshippedInBundle &&
            record.shipping?.labelProvider?.shipmentId != null &&
            ( !record.shippingProvider ||
              record.shippingProvider === "EasyPost" ) )
        }
      >
        <TextField label="ID" source="id" sortable={false} />
        <TextField source="status" sortable={false} />
        {( isAdmin || isPartner || isCs ) && (
          <BooleanField
            label="Has Manifest"
            source="hasManifest"
            sortable={false}
          />
        )}
        {loaded && !isPartner && (
          <TextField
            source="shippingProvider"
            label="Shipping Provider"
            emptyText={"EasyPost"}
            sortable={false}
          />
        )}
        {loaded && ( isAdmin || isCs ) && (
          <TextField
            source="fulfilledBy"
            label="Fulfilled By"
            sortable={false}
          />
        )}
        {loaded && ( isAdmin || isCs ) && (
          <FunctionField
            label="Customer"
            render={( record: BundledOrder ) => (
              <Box style={{ width: "150px" }}>
                {record.address.firstname} {record.address.lastname}
              </Box>
            )}
            sortable={false}
          />
        )}
        {loaded && (
          <FunctionField
            label="Shipping Address"
            render={( record: BundledOrder ) => (
              <Box style={{ width: "300px" }}>
                {`
                  ${record.address.line1 ? `${record.address.line1},` : ""}
                  ${record.address.line2 ? `${record.address.line2},` : ""}
                  ${record.address.city ? `${record.address.city},` : ""}
                  ${record.address.state ? `${record.address.state},` : ""}
                  ${
              record.address.postalCode
                ? `${record.address.postalCode},`
                : ""
              }
                  ${record.address.country || ""}`}
              </Box>
            )}
            style={{ width: "1000px" }}
            sortable={false}
          />
        )}
        <TextField
          source="shipping.trackingNumber"
          label="Tracking Number"
          sortable={false}
        />
        <FunctionField
          label="Shipping Label"
          render={( record: BundledOrder ) => (
            <RenderUrl
              url={record.shipping?.labelProvider?.labelUrl}
              label="Shipping Label"
            />
          )}
        />
        {( isAdmin || hasOtherRoles || hasAccessToCommercialInvoice ) && (
          <FunctionField
            label="Commercial Invoice"
            render={( record: BundledOrder ) => (
              <RenderUrl
                url={record.shipping?.labelProvider?.commercialInvoiceUrl || ""}
                label="Commercial Invoice"
              />
            )}
          />
        )}
        {( isAdmin || isPartner || isCs ) && (
          <FunctionField
            label="Manifest Form"
            render={( record: BundledOrder ) => (
              <RenderUrl url={record.manifest?.form_url} label="Manifest" />
            )}
          />
        )}
        <FunctionField
          label="Orders"
          sortable={false}
          render={( record: BundledOrder ) =>
            "[" +
            record.orderIds.map( ( orderId ) => `"${orderId}"` ).join( ", " ) +
            "]"
          }
        />
      </Datagrid>
    </List>
  );
};

export const BundledOrderList = (
  properties: Record<string, never>
): JSX.Element => (
  <UserContextProvider>
    <BundledOrderListInner {...properties} />
  </UserContextProvider>
);
