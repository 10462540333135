import React from "react";
import { useUpdate, useRefresh } from "react-admin";
import { Grid, FormControl, FormLabel, Button, Input } from "@material-ui/core";
import { Spacer } from "./../generics/spacer";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { ButtonStyles } from "../generics/button-styles";
import { useNotification } from "../custom-notification/notification";

interface IRefundButton {
  orderId: string;
}

export const ResolveDeliveryButton: React.FunctionComponent<IRefundButton> = (
  properties
) =>
{
  const { showNotification } = useNotification();
  const refresh = useRefresh();
  const buttonClasses = ButtonStyles();
  const [open, setOpen] = React.useState( false );
  const [note, setNote] = React.useState( " " );

  const [resolveDelivery, { loading }] = useUpdate(
    "resolve-delivery",
    properties.orderId,
    {
      note,
    },
    {},
    {
      onSuccess: () =>
      {
        setOpen( false );
        showNotification( {
          message: "Delivery resolved",
          type: "notify",
          timeout: 2000,
          onClose: () => window.location.reload(),
        } );
      },
      onFailure: ( error: Error ) =>
      {
        setOpen( false );
        if ( typeof error === "string" )
        {
          error = JSON.parse( error );
        }
        showNotification( {
          message: error.message,
          type: "error",
          onClose: () => refresh(),
        } );
      },
    }
  );

  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };

  const inputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setNote( event.target.value );
  };

  const body = (
    <div>
      <Spacer />
      <Grid style={{ minWidth: "500px" }} container spacing={0}>
        <FormLabel>Note</FormLabel>
        <FormControl fullWidth={true}>
          <Grid xs={12}>
            <Input
              name="note"
              placeholder={note}
              multiline={true}
              rows="6"
              aria-describedby="my-helper-text"
              type="text"
              fullWidth={true}
              disableUnderline={true}
              required={true}
              onChange={inputChange}
              style={{
                borderStyle: "solid",
                borderWidth: "thin",
                borderColor: "grey",
              }}
            />
          </Grid>
        </FormControl>
      </Grid>
      <Spacer />
      <Button onClick={resolveDelivery} disabled={note === " " || !note}>
        Resolve
      </Button>
    </div>
  );

  const buttonText = "Resolve Delivery";
  return (
    <>
      <Button
        onClick={handleOpen}
        className={buttonClasses.saveButton}
        disabled={loading}
        disableRipple
        disableFocusRipple
      >
        {buttonText}
      </Button>
      <CustomModal open={open} onClose={handleClose}>
        {body}
      </CustomModal>
    </>
  );
};
