import * as React from "react";
import { useCreate, useDataProvider, useNotify } from "react-admin";
import { Typography, Box, Link } from "@material-ui/core";
import { AuthenticationShippingRate } from "./authentication-shipping-rates";
import { authenticationShippingDirection } from "./authentication-shipping-label";
import { resetHistory } from "../../../utils/reset-history";
import { notifyMessage } from "../../../utils/primitive";
import { ShipmentData } from "../../../ts/interfaces/shipping-interface";
interface IShippingPurchase {
  shippingId: string;
  orderId: string;
  rateId: string;
  rate: AuthenticationShippingRate;
  direction: authenticationShippingDirection;
  shipmentData?: ShipmentData;
}

interface ShippingLabel {
  carrier: string;
  id: string;
  labelType: string;
  labelUrl: string;
  rateCurrency: string;
  rateFee: string;
  service: string;
  trackingCode: string;
  commercialInvoiceUrl?: string;
}

export const AuthenticationShippingPurchase = (
  properties: IShippingPurchase
): JSX.Element =>
{
  const notify = useNotify();
  const [confirmedLabel, displayLabel] = React.useState( {} as ShippingLabel );
  const dataProvider = useDataProvider();

  const [create, { loading, error }] = useCreate(
    "authentication-shipping-labels",
    { creation: { ...properties } },
    {
      onSuccess: ( response: { data: ShippingLabel } ) =>
      {
        displayLabel( response.data );
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
        setTimeout( () =>
        {
          resetHistory();
          history.go();
        }, 2000 );
      },
    }
  );
  React.useEffect( () =>
  {
    create();
  }, [] );

  React.useEffect( () =>
  {
    if ( !loading && error )
    {
      notify( error, "warning" );
    }
  }, [loading, error] );

  const useSignedUrl = ( labelUrl: string | undefined ): string | null =>
  {
    const [url, setUrl] = React.useState<string | null>( null );
    const fetchUrl = async ( url: string ) =>
    {
      const { data }: { data: { url: string } } =
        await dataProvider.getSignedUrl( "signedUrl", { path: url } );
      setUrl( data.url );
    };
    React.useEffect( () =>
    {
      if ( labelUrl && !labelUrl.startsWith( "http" ) )
      {
        fetchUrl( labelUrl );
      }
      else
      {
        setUrl( labelUrl );
      }
    }, [labelUrl] );
    return url;
  };
  const confirmedLabelUrl = useSignedUrl( confirmedLabel.labelUrl );

  return (
    <>
      {Object.keys( confirmedLabel ).length > 0 && (
        <Box display={"block"}>
          <Typography>
            {confirmedLabel.carrier}{" "}
            <Link target={"_blank"} href={confirmedLabelUrl}>
              Shipping Label
            </Link>
          </Typography>
          {confirmedLabel.commercialInvoiceUrl && (
            <Typography>
              <Link
                target={"_blank"}
                href={confirmedLabel.commercialInvoiceUrl}
              >
                Commercial Invoice
              </Link>
            </Typography>
          )}
          <Typography>Order Id: {properties.orderId}</Typography>
          <Typography>
            Tracking number: {confirmedLabel.trackingCode}
          </Typography>
          <Typography>Direction: {properties.direction}</Typography>
        </Box>
      )}
    </>
  );
};
