import * as React from "react";
import { useCreate, useDataProvider } from "react-admin";
import { Typography, Box, Link } from "@material-ui/core";
import { FulfillOrders, Shipping } from "./fulfill-orders";
import { ShippingRate } from "./shipping-rates";
import { ShipmentData } from "../../ts/interfaces/shipping-interface";
import { useNotification } from ".././custom-notification/notification";
import { useLocation } from "react-router";
import { useUserContext } from "../../contexts/user-context";
import { usePermissions } from "ra-core";
import {
  BooleanRoles,
  hasPermissions
} from "../../ts/interfaces/role-interface";

interface IShippingPurchase {
  shippingId: string;
  bundledOrdersId: string;
  rateId: string;
  rate: ShippingRate;
  shipmentData?: ShipmentData;
}

interface ShippingLabel {
  carrier: string;
  id: string;
  labelType: string;
  labelUrl: string;
  rateCurrency: string;
  rateFee: string;
  service: string;
  trackingCode: string;
  provider: string;
  commercialInvoiceUrl?: string;
}

interface Carriers {
  [x: string]: string;
}
const carrierIds: Carriers = {
  CanadaPost: "cp",
  USPS: "usps",
  UPS: "ups",
  FedEx: "fedex",
  Purolator: "pu",
  DHLExpress: "dhlexpress",
};

export const ShippingPurchase = (
  properties: IShippingPurchase
): JSX.Element =>
{
  const { permissions } = usePermissions();
  const isAdmin = hasPermissions(
    [
      BooleanRoles.Admin,
      BooleanRoles.Fulfillment,
      BooleanRoles.CustomerService,
      BooleanRoles.FulfillmentReadOnly,
      BooleanRoles.RefundCustomerService,
    ],
    permissions
  );
  const { hasAccessToCommercialInvoice } = useUserContext();
  const location = useLocation();
  const queryParams = new URLSearchParams( location.search );
  const fromReGenerateBundle = queryParams.get( "reGenerate" ) === "true";

  const [confirmedLabel, displayLabel] = React.useState( {} as ShippingLabel );
  const [shipping, setShipping] = React.useState( {} as Shipping );
  const [rateFee, setRateFee] = React.useState( 0 );
  const { showNotification } = useNotification();
  const dataProvider = useDataProvider();

  const [create, { loading, error }] = useCreate(
    "shipping-labels",
    { creation: { ...properties } },
    {
      onSuccess: ( response: { data: ShippingLabel } ) =>
      {
        const carrier = response.data.carrier;
        setRateFee( Math.round( ( Number( response.data.rateFee ) || 0 ) * 100 ) ); //Convert to cent
        displayLabel( response.data );
        const {
          deliverDate,
          deliveryGuarantee,
          deliveryDays,
          estDeliveryDays,
        } = properties.rate;
        const shippingPayload: Shipping = {
          carrier: carrierIds[carrier] ? carrierIds[carrier] : carrier,
          trackingNumber: response.data.trackingCode,
          labelProvider: {
            shipmentId: properties.shippingId,
            rateId: properties.rateId,
            currency: response.data.rateCurrency,
            deliveryDate: deliverDate,
            ...( deliveryGuarantee && {
              deliveryDateGuaranteed: deliveryGuarantee,
            } ),
            deliveryDays,
            estDeliveryDays,
            labelFee: parseInt( response.data.rateFee, 10 ),
            labelUrl: response.data.labelUrl,
            provider: response.data.provider,
            ...( response.data?.commercialInvoiceUrl && {
              commercialInvoiceUrl: response.data.commercialInvoiceUrl,
            } ),
          },
        };
        setShipping( shippingPayload );
      },
      onFailure: () =>
      {
        showNotification( {
          message: `${error}`,
          type: "error",
        } );
      },
    }
  );

  const regenerateLabel = () =>
  {
    dataProvider
      .regenerateLabel( "shipping-labels", properties )
      .then( ( response: { data: ShippingLabel } ) =>
      {
        const carrier = response.data.carrier;
        setRateFee( Math.round( ( Number( response.data.rateFee ) || 0 ) * 100 ) ); //Convert to cent
        displayLabel( response.data );
        const {
          deliverDate,
          deliveryGuarantee,
          deliveryDays,
          estDeliveryDays,
        } = properties.rate;
        const shippingPayload: Shipping = {
          carrier: carrierIds[carrier] ? carrierIds[carrier] : carrier,
          trackingNumber: response.data.trackingCode,
          labelProvider: {
            shipmentId: properties.shippingId,
            rateId: properties.rateId,
            currency: response.data.rateCurrency,
            deliveryDate: deliverDate,
            ...( deliveryGuarantee && {
              deliveryDateGuaranteed: deliveryGuarantee,
            } ),
            deliveryDays,
            estDeliveryDays,
            labelFee: parseInt( response.data.rateFee, 10 ),
            labelUrl: response.data.labelUrl,
            provider: response.data.provider,
            ...( response.data?.commercialInvoiceUrl && {
              commercialInvoiceUrl: response.data.commercialInvoiceUrl,
            } ),
          },
        };
        setShipping( shippingPayload );
      } )
      .catch( ( error: Error ) =>
      {
        showNotification( {
          message: `${error}`,
          type: "error",
          timeout: 4000,
          onClose: () => window.location.reload(),
        } );
      } );
  };

  React.useEffect( () =>
  {
    if ( fromReGenerateBundle )
    {
      regenerateLabel();
    }
    else
    {
      create();
    }
  }, [] );

  React.useEffect( () =>
  {
    if ( !loading && error )
    {
      showNotification( {
        message: `${error}`,
        type: "error",
      } );
    }
  }, [loading, error] );

  const useSignedUrl = ( labelUrl: string | undefined ): string | null =>
  {
    const [url, setUrl] = React.useState<string | null>( null );
    const fetchUrl = async ( url: string ) =>
    {
      const { data }: { data: { url: string } } =
        await dataProvider.getSignedUrl( "signedUrl", { path: url } );
      setUrl( data.url );
    };
    React.useEffect( () =>
    {
      if ( labelUrl && !labelUrl.startsWith( "http" ) )
      {
        fetchUrl( labelUrl );
      }
      else
      {
        setUrl( labelUrl );
      }
    }, [labelUrl] );
    return url;
  };
  const confirmedLabelUrl = useSignedUrl( confirmedLabel.labelUrl );

  return (
    <>
      {Object.keys( confirmedLabel ).length > 0 && (
        <Box display={"block"}>
          <Typography>
            {confirmedLabel.carrier}{" "}
            <Link target={"_blank"} href={confirmedLabelUrl}>
              Shipping Label
            </Link>
          </Typography>
          {confirmedLabel.commercialInvoiceUrl &&
            ( isAdmin || hasAccessToCommercialInvoice ) && (
            <Typography>
              <Link
                target={"_blank"}
                href={confirmedLabel.commercialInvoiceUrl}
              >
                  Commercial Invoice
              </Link>
            </Typography>
          )}
          <Typography>Bundle ID: {properties.bundledOrdersId}</Typography>
          <Typography>
            Tracking number: {confirmedLabel.trackingCode}
          </Typography>
        </Box>
      )}
      {Object.keys( shipping ).length > 0 && (
        <FulfillOrders
          bundledOrdersId={properties.bundledOrdersId}
          shipping={shipping}
          rateFee={rateFee}
          fromReGenerateBundle={fromReGenerateBundle}
        />
      )}
    </>
  );
};
