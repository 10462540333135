import * as React from "react";
import { useDataProvider, usePermissions } from "react-admin";
import { ReturnButton } from "../generics/return-action-button";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Box
} from "@material-ui/core";
import { ActionButton } from "../generics/buttons";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { LocationButton } from "./location-button";
import { PrintButton } from "./print-button";
import { Fulfillments, Item } from "../../ts/interfaces/order-interface";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  extractAuthClaims,
  hasPermissions,
  BooleanRoles
} from "../../ts/interfaces/role-interface";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useBarcode } from "../../contexts/barcode-context";

const useStyles = makeStyles( () =>
  createStyles( {
    table: {
      border: 0,
    },
    column: {
      flexBasis: "49.75%",
    },
    assignment: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    nestedrow: {
      borderBottom: "1px solid #e0e0e3",
    },
    accordionSummary: {
      flexDirection: "row-reverse",
    },
  } )
);

interface IFulfillmentTable {
  fulfillment: Fulfillments;
  orderId: string;
  orderStatus: string;
  latestDisputeStatus?: string;
  disputeResolved?: boolean;
  timestampsFulfilled?: Date;
  fulfilledBy: "ShopThing" | "Seller" | "Partner";
  item: Item;
  setGenerateReturnLabel?: React.Dispatch<
    React.SetStateAction<{ fulfillmentId: string; show: boolean }>
  >;
}

export const FulfillmentTable = ( {
  fulfillment,
  orderId,
  orderStatus,
  latestDisputeStatus,
  disputeResolved,
  item,
  timestampsFulfilled,
  setGenerateReturnLabel,
  fulfilledBy,
}: IFulfillmentTable ): JSX.Element =>
{
  const ldClient = useLDClient();
  const [expand, setExpand] = React.useState( false );
  const toggleAcordion = () =>
  {
    setExpand( ( prev ) => !prev );
  };

  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const dataProvider = useDataProvider();

  const { userContext } = useBarcode();
  const [isSellerOrPartnerReturn, setIsSellerOrPartnerReturn] =
    React.useState( false );

  React.useEffect( () =>
  {
    const featureEnabled = ldClient.variation(
      "enableReturnsForGigShoppers",
      false
    );

    const sellerOrPartner = hasPermissions(
      [BooleanRoles.Seller, BooleanRoles.Partner],
      roleClaims
    );

    setIsSellerOrPartnerReturn( featureEnabled && sellerOrPartner );
  }, [userContext, loaded] );

  const fetchSignedUrl = ( path: string ) =>
  {
    dataProvider
      .getSignedUrl( "signedUrl", { path } )
      .then( ( { data }: { data: { url: string } } ) =>
      {
        window.open( data.url );
      } );
  };

  return (
    <>
      <Accordion expanded={expand}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{ size: "medium", onClick: toggleAcordion }}
        >
          <TableContainer>
            <Table aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow className={classes.table}>
                  <TableCell className={classes.table} align="left">
                    <Typography variant={"caption"}>Id</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography variant={"caption"}>Status</Typography>
                  </TableCell>
                  {fulfillment.location ||
                  ( loaded &&
                    hasPermissions(
                      [
                        BooleanRoles.CustomerService,
                        BooleanRoles.RefundCustomerService,
                        BooleanRoles.Fulfillment,
                        BooleanRoles.Admin,
                      ],
                      roleClaims
                    ) ) ? (
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"caption"}>Location</Typography>
                      </TableCell>
                    ) : null}
                  <TableCell className={classes.table} align="left">
                    <Typography variant={"caption"}>Sku</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography variant={"caption"}>Quantity</Typography>
                  </TableCell>
                  {hasPermissions(
                    [
                      BooleanRoles.Admin,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Partner,
                    ],
                    roleClaims
                  ) && (
                    <TableCell className={classes.table} align="right">
                      <Typography variant={"caption"}>
                        Reprint Barcode{" "}
                      </Typography>
                    </TableCell>
                  )}
                  {loaded &&
                    hasPermissions(
                      [
                        BooleanRoles.Fulfillment,
                        BooleanRoles.Admin,
                        BooleanRoles.Seller,
                        BooleanRoles.Partner,
                      ],
                      roleClaims
                    ) &&
                    ( latestDisputeStatus ? disputeResolved : true ) &&
                    ["fulfilled", "refunded"].includes( orderStatus ) &&
                    ( !fulfillment.returnStatus ||
                      ["initiated", "closed"].includes(
                        fulfillment.returnStatus
                      ) ) && (
                    <TableCell className={classes.table} align="left">
                      <Typography variant={"caption"}>Returns</Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.table} align="left">
                    <Typography>{fulfillment.externalId}</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography>{fulfillment.status}</Typography>
                  </TableCell>
                  {fulfillment.location ? (
                    <TableCell className={classes.table} align="left">
                      <Typography>{fulfillment.location}</Typography>
                    </TableCell>
                  ) : (
                    loaded &&
                    hasPermissions(
                      [
                        BooleanRoles.CustomerService,
                        BooleanRoles.RefundCustomerService,
                        BooleanRoles.Fulfillment,
                        BooleanRoles.Admin,
                      ],
                      roleClaims
                    ) && (
                      <TableCell
                        className={classes.table}
                        align="left"
                        onClick={(
                          event: React.MouseEvent<
                            HTMLTableHeaderCellElement,
                            MouseEvent
                          >
                        ) =>
                        {
                          event.stopPropagation();
                        }}
                      >
                        <Typography>
                          <LocationButton
                            orderId={orderId}
                            fulfillmentId={fulfillment.externalId}
                            location={item.location}
                          />
                        </Typography>
                      </TableCell>
                    )
                  )}
                  <TableCell className={classes.table} align="left">
                    <Typography>
                      {fulfillment.items
                        ? fulfillment.items[0].skuId
                        : "No Item"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography>
                      {" "}
                      {fulfillment.items
                        ? fulfillment.items[0].quantity
                        : "No Item"}
                    </Typography>
                  </TableCell>
                  {hasPermissions(
                    [
                      BooleanRoles.Admin,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Partner,
                    ],
                    roleClaims
                  ) && (
                    <TableCell className={classes.table} align="right">
                      <PrintButton
                        fulfillmentId={fulfillment.externalId}
                        orderId={orderId}
                        skuId={item.skuId}
                        item={item}
                        productInventoryItemId={
                          fulfillment.productInventoryItemId
                        }
                      />
                    </TableCell>
                  )}
                  <TableCell className={classes.table} align="center">
                    {loaded &&
                      hasPermissions(
                        [BooleanRoles.Fulfillment, BooleanRoles.Admin],
                        roleClaims
                      ) &&
                      ( latestDisputeStatus ? disputeResolved : true ) &&
                      ( ["fulfilled", "refunded"].includes( orderStatus ) ||
                        ( ["disputeLost", "canceled"].includes( orderStatus ) &&
                          disputeResolved &&
                          !timestampsFulfilled ) ) &&
                      ( !fulfillment.returnStatus ||
                        fulfillment.returnStatus === "closed" ) && (
                      <ReturnButton
                        id={orderId}
                        action={"initiate-return"}
                        successMessage={"Return initiated"}
                        additionalBodyParams={{
                          fulfillmentId: fulfillment.externalId,
                        }}
                        type={"initiate"}
                        label={"Initiate Return"}
                        includeReasons={true}
                      />
                    )}
                    {( ( loaded &&
                      hasPermissions(
                        [BooleanRoles.Fulfillment, BooleanRoles.Admin],
                        roleClaims
                      ) ) ||
                      isSellerOrPartnerReturn ) &&
                      ( latestDisputeStatus ? disputeResolved : true ) &&
                      ( ["fulfilled", "refunded"].includes( orderStatus ) ||
                        ( ["disputeLost", "canceled"].includes( orderStatus ) &&
                          disputeResolved &&
                          !timestampsFulfilled ) ) &&
                      fulfillment.returnStatus === "initiated" && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="flex-end"
                      >
                        {setGenerateReturnLabel &&
                            hasPermissions(
                              [BooleanRoles.Fulfillment, BooleanRoles.Admin],
                              roleClaims
                            ) &&
                            fulfilledBy !== "Partner" &&
                            !(
                              // Make sure the return label is not generated yet
                              (
                                fulfillment.returnShippingLabel &&
                                Object.values( fulfillment.returnShippingLabel )
                                  .length > 0
                              )
                            ) && (
                          <ActionButton
                            label="Generate Return Label"
                            onClick={() =>
                              setGenerateReturnLabel( {
                                fulfillmentId: fulfillment.externalId,
                                show: true,
                              } )
                            }
                          />
                        )}
                        {fulfillment.returnShippingLabel &&
                            Object.values( fulfillment.returnShippingLabel )
                              .length > 0 &&
                            fulfillment.returnShippingLabel?.labelProvider
                              ?.labelUrl &&
                            fulfilledBy !== "Partner" && (
                          <Box display="flex" flexDirection="column">
                            <ActionButton
                              label="View Return Label"
                              onClick={() =>
                              {
                                const { labelUrl } =
                                      fulfillment.returnShippingLabel
                                        .labelProvider;
                                if ( !labelUrl.startsWith( "http" ) )
                                {
                                  fetchSignedUrl( labelUrl );
                                }
                                else
                                {
                                  window.open( labelUrl );
                                }
                              }}
                            />
                            {fulfillment.returnShippingLabel?.labelProvider
                              ?.commercialInvoiceUrl && (
                              <ActionButton
                                label="View Return Commercial Invoice"
                                onClick={() =>
                                {
                                  window.open(
                                    fulfillment.returnShippingLabel
                                      .labelProvider.commercialInvoiceUrl
                                  );
                                }}
                              />
                            )}
                          </Box>
                        )}
                        <ReturnButton
                          id={fulfillment.productInventoryItemId}
                          successMessage={"Return accepted"}
                          action={"return"}
                          type={"accept"}
                          label={"Accept Return"}
                          includeLocation={!isSellerOrPartnerReturn}
                          includeReasons={true}
                          additionalBodyParams={{
                            action: "accept",
                          }}
                        />
                        {hasPermissions(
                          [BooleanRoles.Fulfillment, BooleanRoles.Admin],
                          roleClaims
                        ) && (
                          <ReturnButton
                            id={fulfillment.productInventoryItemId}
                            successMessage={"Return closed"}
                            action={"return"}
                            type={"close"}
                            label={"Close Return"}
                            includeReasons={true}
                            additionalBodyParams={{
                              action: "close",
                            }}
                          />
                        )}
                      </Box>
                    )}
                    {loaded &&
                      hasPermissions(
                        [BooleanRoles.Fulfillment, BooleanRoles.Admin],
                        roleClaims
                      ) &&
                      ( latestDisputeStatus ? disputeResolved : true ) &&
                      ( ["fulfilled", "refunded"].includes( orderStatus ) ||
                        ( ["disputeLost", "canceled"].includes( orderStatus ) &&
                          disputeResolved &&
                          !timestampsFulfilled ) ) &&
                      fulfillment.returnStatus === "accepted" && (
                      <ReturnButton
                        id={orderId}
                        action={"resolve-return"}
                        successMessage={"Return resolved"}
                        additionalBodyParams={{
                          fulfillmentId: fulfillment.externalId,
                        }}
                        type={"resolve"}
                        label={"Resolve refunded return"}
                      />
                    )}
                    {loaded &&
                      hasPermissions(
                        [BooleanRoles.Seller, BooleanRoles.Partner],
                        roleClaims
                      ) &&
                      !hasPermissions( [BooleanRoles.Admin], roleClaims ) &&
                      fulfillment.returnStatus === "accepted" && (
                      <Typography>Return Accepted</Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionSummary>
        {fulfillment?.barcodeAssignment && (
          <AccordionDetails>
            <div className={classes.assignment}>
              {" "}
              <div className={classes.column}>
                <Box bgcolor="#f7f9fa">
                  {" "}
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>
                              Barcode Status
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow className={classes.nestedrow}>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"h6"}>Generated By</Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="right">
                            <Typography>
                              {fulfillment.barcodeAssignment.assignedBy}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"h6"}>Generated On</Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="right">
                            <Typography>
                              {new Date(
                                /* eslint-disable-next-line no-underscore-dangle */
                                fulfillment.barcodeAssignment.assignedAt
                                  ._seconds * 1000
                              ).toLocaleString( "en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              } )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </div>
              <div className={classes.column}>
                <Box bgcolor="#f7f9fa">
                  {fulfillment?.locationAssignment && (
                    <TableContainer>
                      <Table
                        aria-label="simple table"
                        className={classes.table}
                      >
                        <TableHead>
                          <TableRow className={classes.table}>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                Location Assignment
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow className={classes.nestedrow}>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"h6"}>
                                Assigned By
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="right">
                              <Typography>
                                {fulfillment.locationAssignment.assignedBy}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"h6"}>
                                Assigned On
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="right">
                              <Typography>
                                {new Date(
                                  /* eslint-disable-next-line no-underscore-dangle */
                                  fulfillment.locationAssignment.assignedAt
                                    ._seconds * 1000
                                ).toLocaleString( "en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                } )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              </div>
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};
